<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-21 17:10:32
 * @ Description: Component displaying pending job cards for individuals.
 -->

<template>
    <v-card
        :height="$vuetify.breakpoint.mdAndUp ? '516px' : ''"
        width="100%"
        max-width="280px"
        class="rounded-lg pa-3 mx-auto"
    >
        <v-row
            align="center"
        >
            <!-- Message Count -->
            <v-col
                class="os-12-sb pa-2 pb-0"
                cols="7"
                style="color: #FFA858;"
            >
                <v-btn
                    icon
                    aria-label="notifications"
                >
                    <!-- <v-badge
                        overlap
                        color="#FFA858"
                        dot
                    > -->
                    <v-icon
                        color="#2E567A"
                        size="20"
                    >
                        {{ mdiBell }}
                    </v-icon>
                    <!-- </v-badge> -->
                </v-btn>
                {{ item.applicant_unread_messages }} Message<span v-if="item.applicant_unread_messages !== 1">s</span>
            </v-col>
            <!-- Message Count -->

            <!-- Job Details -->
            <v-col
                class="text-right os-12-sb pa-2 pb-0"
                cols="5"
                style="color: #2E567A;"
            >
                {{ item.create_date }}
            </v-col>
            <v-col
                cols="12"
                class="pa-2"
            >
                <div
                    style="height: 80px; display: flex; align-items: center;"
                >
                    <v-img
                        :src="item.banner ? item.banner : '/general/FixerCompanyIcon.png'"
                        max-height="80px"
                        max-width="80px"
                        :height="$vuetify.breakpoint.mdAndUp && item.banner ? '80px' : $vuetify.breakpoint.mdAndUp ? '50px' : ''"
                        :width="$vuetify.breakpoint.mdAndUp && item.banner ? '80px' : $vuetify.breakpoint.mdAndUp ? '50px' : ''"
                        contain
                        class="mx-auto"
                    />
                </div>
            </v-col>
            <v-col
                cols="12"
                style="color: #8F9BB3; cursor: pointer; text-decoration: underline;"
                class="os-10-r text-center py-0"
                @click="goToCompanyProfile(item.company_id)"
            >
                {{ item.company_name }}
            </v-col>
            <v-col
                cols="12"
                class="os-16-sb two-lines text-center"
                style="height: 60px; max-height: 60px; display: flex; align-items: center; justify-content: center;"
            >
                {{ item.name }}
            </v-col>
            <v-col
                cols="12"
                class="os-12-r four-lines text-center"
                style="color: #8F9BB3; height: 83px; max-height: 83px;"
                v-html="item.description"
                v-if="item.description"
            />
            <v-col
                cols="12"
                class="os-12-r four-lines text-center"
                style="color: #8F9BB3; height: 83px; max-height: 83px; display: flex; align-items: center; justify-content: center;"
                v-else
            >
                No description added
            </v-col>
            <v-col
                cols="12"
                class="pb-0 pt-6"
                style="position: relative;"
                v-if="$vuetify.breakpoint.mdAndUp"
            >
                <v-divider />
                <span
                    class="os-12-b"
                    style="position: absolute; top: 0; right: 12px; color: #8F9BB3; cursor: pointer;"
                    @click="jobDetail"
                >
                    Read more...
                </span>
            </v-col>
            <v-col
                cols="12"
                v-if="$vuetify.breakpoint.mdAndUp"
            >
                <div
                    class="os-10-r"
                    style="color: #8F9BB3;"
                >
                    Industry:
                </div>
                <div
                    class="os-12-sb"
                    style="color: #2E567A;"
                >
                    {{ item.industry_name }}
                </div>
                <div
                    class="os-10-r"
                    style="color: #8F9BB3;"
                >
                    Location:
                </div>
                <div
                    class="os-12-sb text-truncate"
                    style="color: #2E567A;"
                >
                    <span v-if="item.mode_id === 1">-</span>
                    <span v-else-if="item.city && item.country">{{ item.city }} - {{ item.country }}</span>
                    <span v-else-if="item.state && item.country">{{ item.state }} - {{ item.country }}</span>
                    <span v-else-if="item.country">{{ item.country }}</span>
                    <span v-else>No address added</span>
                </div>
                <div
                    class="os-10-r"
                    style="color: #8F9BB3;"
                >
                    Type:
                </div>
                <div
                    class="os-12-sb"
                    style="color: #2E567A;"
                >
                    {{ item.type_name }}
                </div>
                <div
                    class="os-10-r"
                    style="color: #8F9BB3;"
                >
                    Mode:
                </div>
                <div
                    class="os-12-sb text-truncate"
                    style="color: #2E567A;"
                >
                    {{ item.mode_name }}
                </div>
            </v-col>
            <v-col
                cols="12"
                class="pb-0 py-md-0"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="6"
                class="py-0"
                v-if="$vuetify.breakpoint.smAndDown"
            >
                <v-btn
                    text
                    style="text-decoration: underline; color: #2E567A; cursor: pointer;"
                    class="os-12-sb no-uppercase px-0"
                    @click="jobDetail"
                >
                    More information
                </v-btn>
            </v-col>
            <v-col
                cols="6"
                md="12"
                class="text-right py-0"
            >
                <v-btn
                    text
                    style="text-decoration: underline; color: #2E567A;"
                    class="os-12-sb no-uppercase px-0"
                    @click="jobViewStatus"
                >
                    View Status
                </v-btn>
            </v-col>
            <!-- Job Details -->
        </v-row>
    </v-card>
</template>
<script>
    import { mdiBell } from '@mdi/js'

    export default {
        name: 'WorkJobIndividualPendingCardComponent',

        props: {
            item: {
                type: Object,
                required: true
            }
        },

        data () {
            return {
                mdiBell
            }
        },

        methods: {
            jobDetail () {
                this.$emit('jobDetail', this.item)
            },

            jobViewStatus () {
                this.$emit('jobViewStatus', this.item)
            },

            goToCompanyProfile (companyID) {
                this.$router.push(`/profile/company/${companyID}`)
            }
        }
    }
</script>
<style scoped>
    .four-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        white-space: normal;
    }

    .two-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: normal;
    }
</style>
