<template>
    <!-- Desktop -->
    <v-dialog
        v-if="$vuetify.breakpoint.mdAndUp"
        v-model="showDialog"
        overlay-color="rgb(34, 43, 69)"
        persistent
        style="height: 100%;"
        :max-width="$vuetify.breakpoint.lgAndUp ? '1440px' : '960px'"
    >
        <v-card
            style="position: relative;"
            color="rgb(249, 251, 253)"
            min-height="calc(100vh - 76px)"
        >
            <v-card-text
                class="pa-6 pb-0"
                style="color: black;"
            >
                <!-- Close Button -->
                <v-btn
                    icon
                    style="position: absolute; right: 16px;"
                    @click="hideJobOverlay"
                    aria-label="close"
                >
                    <v-icon
                        color="black"
                        style="z-index: 10;"
                    >
                        {{ mdiCloseThick }}
                    </v-icon>
                </v-btn>
                <!-- Close Button -->

                <v-row>
                    <!-- Back Button & Job Name -->
                    <v-col
                        cols="12"
                        class="os-22-sb"
                    >
                        <v-btn
                            icon
                            @click="hideJobOverlay"
                            class="mb-1"
                        >
                            <v-icon
                                size="24"
                                color="#2E567A"
                                style="opacity: 0.65;"
                            >
                                {{ mdiArrowLeftThinCircleOutline }}
                            </v-icon>
                        </v-btn>
                        <v-icon
                            color="#2E567A"
                            size="30"
                            class="mr-1"
                        >
                            {{ mdiFlashOutline }}
                        </v-icon>
                        {{ jobToShow.name }}
                    </v-col>
                    <v-col
                        cols="12"
                        class="py-0"
                    >
                        <v-divider
                            style="width: 50%;"
                        />
                    </v-col>
                    <!-- Back Button & Job Name -->

                    <!-- Required Details -->
                    <v-col
                        cols="6"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                class="pb-0 os-15-sb"
                            >
                                Job Name
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="jobToShow.name">{{ jobToShow.name }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No name added
                                    </span>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-15-sb py-0"
                            >
                                Detailed Description
                                <br />
                                <div
                                    v-if="jobToShow.description"
                                    class="os-13-r pt-3"
                                    style="color: #2E567A;"
                                    v-html="jobToShow.description"
                                />
                                <div
                                    v-else-if="!jobToShow.description"
                                    class="os-12-r py-3"
                                    style="color: rgba(0, 0, 0, 0.38);"
                                >
                                    No description added
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                            >
                                Industry
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="jobToShow.industry_id">{{ jobToShow.industry_name }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No industry added
                                    </span>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                            >
                                Job Title
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="jobToShow.job_title_id">{{ jobToShow.job_title_name }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No job title added
                                    </span>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                            >
                                Job Type
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="jobToShow.type_id">{{ jobToShow.type_name }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No type added
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!-- Banner & Edit Button -->
                    <v-col
                        class="os-13-r text-center px-0"
                        cols="3"
                    >
                        <div
                            style="min-height: 100px; display: flex; justify-content: center;"
                        >
                            <v-img
                                :src="jobToShow.banner ? jobToShow.banner : '/general/FixerCompanyIcon.png'"
                                :max-height="jobToShow.banner ? '100px' : '50px'"
                                :max-width="jobToShow.banner ? '100px' : '50px'"
                                contain
                                class="mb-2 mx-auto"
                            />
                        </div>
                        <div
                            class="pt-6"
                            v-if="$store.state.user.userContext === 'individual' && jobToShow.status_id === 2 && !this.$route.path.includes('applications')"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                @click="$router.push(`/job/${jobToShow.job_id}/application`)"
                            >
                                Apply
                            </v-btn>
                        </div>
                    </v-col>
                    <!-- Banner & Edit Button -->

                    <v-col
                        cols="6"
                        class="py-0 os-15-sb"
                    >
                        Job Mode
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="jobToShow.mode_id">{{ jobToShow.mode_name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No mode added
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        v-if="jobToShow.mode_id === 2"
                    >
                        <v-row
                            style="max-width: 50%;"
                        >
                            <v-col
                                cols="12"
                                class="py-0 pr-0 os-15-sb"
                            >
                                Address
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    {{ address.address_line_1 }}
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0 pr-0 os-15-sb"
                            >
                                Country
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="address.country.id">{{ address.country.name }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No country added
                                    </span>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0 pr-0 os-15-sb"
                            >
                                City
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="address.city">{{ address.city }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No city added
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Map -->
                    <v-col
                        cols=""
                        class="pt-0"
                        v-if="jobToShow.mode_id === 2"
                    >
                        <general-map-component
                            :mapMarkers="mapMarkers"
                            :avatar="jobToShow.company_avatar"
                        />
                    </v-col>
                    <!-- Map -->
                    <!-- Required Details -->

                    <!-- More Details -->
                    <v-col
                        cols="12"
                        class="os-22-sb"
                    >
                        <span v-if="jobToShow.user_id === $store.state.user.userData.id && jobToShow.status_id < 3">Add </span>More Details
                    </v-col>
                    <v-col
                        cols="6"
                        class="py-0"
                    >
                        <v-divider />
                    </v-col>
                    <!-- More Details -->

                    <!-- Milestones -->
                    <v-col
                        cols="12"
                        class="os-15-sb pb-0"
                    >
                        <v-btn
                            icon
                            style="mr-1 mb-1"
                            @click="expandPaymentMilestones = !expandPaymentMilestones"
                        >
                            <v-icon
                                color="#FFA838"
                                size="24"
                                v-if="expandPaymentMilestones"
                            >
                                {{ mdiMinusCircle }}
                            </v-icon>
                            <v-icon
                                color="#FFA838"
                                size="24"
                                v-else
                            >
                                {{ mdiPlusCircle }}
                            </v-icon>
                        </v-btn>
                        Payment Milestones
                        <v-row
                            v-if="expandPaymentMilestones"
                            class="pt-3 pb-6"
                        >
                            <v-col
                                cols="6"
                            >
                                <general-work-payment-milestones-component
                                    :canEditPaymentMilestones="false"
                                    :canPayPaymentMilestones="false"
                                    :canRefreshPaymentMilestones="false"
                                    :editMode="false"
                                    :paymentMilestones="paymentMilestones"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Milestones -->

                    <!-- Dates -->
                    <v-col
                        cols="12"
                        class="os-15-sb py-0"
                    >
                        <v-btn
                            icon
                            style="mr-1 mb-1"
                            @click="expandDates = !expandDates"
                        >
                            <v-icon
                                color="#FFA838"
                                size="24"
                                v-if="expandDates"
                            >
                                {{ mdiMinusCircle }}
                            </v-icon>
                            <v-icon
                                v-else
                                color="#FFA838"
                                size="24"
                            >
                                {{ mdiPlusCircle }}
                            </v-icon>
                        </v-btn>
                        Job Date(s)
                        <v-row
                            v-if="expandDates"
                            style="max-width: 50%;"
                            class="pt-3"
                        >
                            <v-col
                                cols="6"
                                class="os-15-sb"
                            >
                                Start Date
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="jobToShow.start_date">{{ jobToShow.start_date }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No start date added
                                    </span>
                                </div>
                            </v-col>
                            <v-col
                                cols="6"
                                class="os-15-sb pr-0"
                            >
                                End Date
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="jobToShow.end_date">{{ jobToShow.end_date }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No end date added
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Dates -->

                    <!-- Documents -->
                    <v-col
                        cols="12"
                        class="os-15-sb pt-0"
                    >
                        <v-btn
                            icon
                            style="mr-1 mb-1"
                            @click="expandDocuments = !expandDocuments"
                        >
                            <v-icon
                                color="#FFA838"
                                size="24"
                                v-if="expandDocuments"
                            >
                                {{ mdiMinusCircle }}
                            </v-icon>
                            <v-icon
                                v-else
                                color="#FFA838"
                                size="24"
                            >
                                {{ mdiPlusCircle }}
                            </v-icon>
                        </v-btn>
                        Document(s)
                        <v-row
                            v-if="expandDocuments"
                            class="py-6"
                            style="max-width: 50%;"
                        >
                            <v-col
                                cols="12"
                            >
                                <general-document-component
                                    :documents="jobDocuments"
                                    :canEditDocuments="false"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Documents -->
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Desktop -->

    <!-- Mobile -->
    <v-dialog
        v-else
        v-model="showDialog"
        overlay-color="rgb(34, 43, 69)"
        persistent
        style="height: 100%;"
        fullscreen
    >
        <v-card
            style="position: relative;"
            color="rgb(249, 251, 253)"
            min-height="100%"
        >
            <v-card-text
                class="pa-6 pb-0"
                style="color: black;"
            >
                <!-- Close Button -->
                <v-btn
                    icon
                    style="position: absolute; right: 16px;"
                    @click="hideJobOverlay"
                    aria-label="close"
                >
                    <v-icon
                        color="black"
                        style="z-index: 10;"
                    >
                        {{ mdiCloseThick }}
                    </v-icon>
                </v-btn>
                <!-- Close Button -->

                <v-row>
                    <!-- Back Button & Job Name -->
                    <v-col
                        cols="12"
                        class="os-22-sb"
                    >
                        <v-btn
                            icon
                            @click="hideJobOverlay"
                            class="mb-1"
                        >
                            <v-icon
                                size="24"
                                color="#2E567A"
                                style="opacity: 0.65;"
                            >
                                {{ mdiArrowLeftThinCircleOutline }}
                            </v-icon>
                        </v-btn>
                        <v-icon
                            color="#2E567A"
                            size="30"
                            class="mr-1"
                        >
                            {{ mdiFlashOutline }}
                        </v-icon>
                        {{ jobToShow.name }}
                    </v-col>
                    <v-col
                        cols="12"
                        class="py-0"
                    >
                        <v-divider />
                    </v-col>
                    <!-- Back Button & Job Name -->

                    <!-- Banner & Edit Button -->
                    <v-col
                        class="os-13-r text-center px-0"
                        cols="12"
                    >
                        <div
                            style="min-height: 100px; display: flex; justify-content: center;"
                        >
                            <v-img
                                :src="jobToShow.banner ? jobToShow.banner : '/dashboard/general/DashboardGeneralUploadImage.png'"
                                max-height="100px"
                                max-width="100px"
                                contain
                                class="mb-2 mx-auto"
                            />
                        </div>
                        <div
                            class="pt-6"
                            v-if="$store.state.user.userContext === 'individual' && jobToShow.status_id === 2 && !this.$route.path.includes('applications')"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                @click="$router.push(`/job/${jobToShow.job_id}/application`)"
                            >
                                Apply
                            </v-btn>
                        </div>
                    </v-col>
                    <!-- Banner & Edit Button -->

                    <!-- Required Details -->
                    <v-col
                        cols="12"
                        class="pb-0 os-15-sb"
                    >
                        Job Name
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="jobToShow.name">{{ jobToShow.name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No name added
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        class="os-15-sb py-0"
                    >
                        Detailed Description
                        <br />
                        <div
                            v-if="jobToShow.description"
                            class="os-13-r pt-3"
                            style="color: #2E567A;"
                            v-html="jobToShow.description"
                        />
                        <div
                            v-else-if="!jobToShow.description"
                            class="os-12-r py-3"
                            style="color: rgba(0, 0, 0, 0.38);"
                        >
                            No description added
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        class="py-0 os-15-sb"
                    >
                        Industry
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="jobToShow.industry_id">{{ jobToShow.industry_name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No industry added
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        class="py-0 os-15-sb"
                    >
                        Job Title
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="jobToShow.job_title_id">{{ jobToShow.job_title_name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No job title added
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        class="py-0 os-15-sb"
                    >
                        Job Type
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="jobToShow.type_id">{{ jobToShow.type_name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No type added
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        class="py-0 os-15-sb"
                    >
                        Job Mode
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="jobToShow.mode_id">{{ jobToShow.mode_name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No mode added
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        v-if="jobToShow.mode_id === 2"
                        cols="12"
                        sm="6"
                        class="py-0 os-15-sb"
                    >
                        Address
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            {{ address.address_line_1 }}
                        </div>
                    </v-col>
                    <v-col
                        v-if="jobToShow.mode_id === 2"
                        cols="12"
                        sm="6"
                        class="py-0 os-15-sb"
                    >
                        Country
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="address.country.id">{{ address.country.name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No country added
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        v-if="jobToShow.mode_id === 2"
                        cols="12"
                        sm="6"
                        class="py-0 os-15-sb"
                    >
                        City
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="address.city">{{ address.city }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No name added
                            </span>
                        </div>
                    </v-col>

                    <!-- Map -->
                    <v-col
                        cols="12"
                        class="pt-0"
                        v-if="jobToShow.mode_id === 2"
                    >
                        <general-map-component
                            :mapMarkers="mapMarkers"
                            :avatar="jobToShow.company_avatar"
                        />
                    </v-col>
                    <!-- Map -->
                    <!-- Required Details -->

                    <!-- More Details -->
                    <v-col
                        cols="12"
                        class="os-22-sb"
                    >
                        <span v-if="jobToShow.user_id === $store.state.user.userData.id && jobToShow.status_id < 3">Add </span>More Details
                    </v-col>
                    <v-col
                        cols="12"
                        class="py-0"
                    >
                        <v-divider />
                    </v-col>
                    <!-- More Details -->

                    <!-- Milestones -->
                    <v-col
                        cols="12"
                        class="os-15-sb pb-0"
                    >
                        <v-btn
                            icon
                            style="mr-1 mb-1"
                            @click="expandPaymentMilestones = !expandPaymentMilestones"
                        >
                            <v-icon
                                color="#FFA838"
                                size="24"
                                v-if="expandPaymentMilestones"
                            >
                                {{ mdiMinusCircle }}
                            </v-icon>
                            <v-icon
                                color="#FFA838"
                                size="24"
                                v-else
                            >
                                {{ mdiPlusCircle }}
                            </v-icon>
                        </v-btn>
                        Payment Milestones
                        <v-row
                            v-if="expandPaymentMilestones"
                            class="py-3 px-2"
                        >
                            <v-col
                                cols="12"
                            >
                                <general-work-payment-milestones-component
                                    :canEditPaymentMilestones="false"
                                    :canPayPaymentMilestones="false"
                                    :canRefreshPaymentMilestones="false"
                                    :editMode="false"
                                    :paymentMilestones="paymentMilestones"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Milestones -->

                    <!-- Dates -->
                    <v-col
                        cols="12"
                        class="os-15-sb py-0"
                    >
                        <v-btn
                            icon
                            style="mr-1 mb-1"
                            @click="expandDates = !expandDates"
                        >
                            <v-icon
                                color="#FFA838"
                                size="24"
                                v-if="expandDates"
                            >
                                {{ mdiMinusCircle }}
                            </v-icon>
                            <v-icon
                                v-else
                                color="#FFA838"
                                size="24"
                            >
                                {{ mdiPlusCircle }}
                            </v-icon>
                        </v-btn>
                        Job Date(s)
                        <v-row
                            v-if="expandDates"
                            class="pt-3"
                        >
                            <v-col
                                cols="12"
                                sm="6"
                                class="os-15-sb"
                                :class="$vuetify.breakpoint.xs ? 'pb-0' : ''"
                            >
                                Start Date
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="jobToShow.start_date">{{ jobToShow.start_date }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No start date added
                                    </span>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                class="os-15-sb"
                                :class="$vuetify.breakpoint.xs ? 'pt-0' : ''"
                            >
                                End Date
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="jobToShow.end_date">{{ jobToShow.end_date }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No end date added
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Dates -->

                    <!-- Documents -->
                    <v-col
                        cols="12"
                        class="os-15-sb pt-0"
                    >
                        <v-btn
                            icon
                            style="mr-1 mb-1"
                            @click="expandDocuments = !expandDocuments"
                        >
                            <v-icon
                                color="#FFA838"
                                size="24"
                                v-if="expandDocuments"
                            >
                                {{ mdiMinusCircle }}
                            </v-icon>
                            <v-icon
                                v-else
                                color="#FFA838"
                                size="24"
                            >
                                {{ mdiPlusCircle }}
                            </v-icon>
                        </v-btn>
                        Document(s)
                        <v-row
                            v-if="expandDocuments"
                            class="py-6"
                        >
                            <v-col
                                cols="12"
                            >
                                <general-document-component
                                    :documents="jobDocuments"
                                    :canEditDocuments="false"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Documents -->
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Mobile -->
</template>
<script>
    import { mdiCloseThick, mdiFlashOutline, mdiMinusCircle, mdiPlusCircle, mdiArrowLeftThinCircleOutline } from '@mdi/js'
    import GeneralWorkPaymentMilestonesComponent from '@/components/general/work/GeneralWorkPaymentMilestonesComponent.vue'
    import GeneralDocumentComponent from '@/components/general/document/GeneralDocumentComponent.vue'
    import { WorkJobDocumentController, WorkJobLocationController, WorkJobMilestoneController } from '@/logic/controller/index.controller.js'

    export default {
        name: 'WorkJobDetailViewOverlayComponent',

        components: {
            GeneralWorkPaymentMilestonesComponent,
            GeneralDocumentComponent
        },

        props: {
            jobToShow: {
                type: Object,
                required: true
            },

            showDialog: {
                type: Boolean,
                required: true,
                default: false
            }
        },

        watch: {
            showDialog: {
                async handler (value) {
                    if (value) {
                        await this.getJobDocuments()
                        await this.getJobLocation()
                        await this.getJobPaymentMilestones()
                    } else {
                        this.expandDates = false
                        this.expandDocuments = false
                        this.expandPaymentMilestones = false
                        this.paymentMilestones = []
                        this.jobDocuments = []
                        this.address = {
                            job_id: null,
                            user_id: null,
                            address_line_1: null,
                            google_places_id: null,
                            lat: null,
                            lng: null,
                            state: null,
                            city: null,
                            country: {},
                            timezone: null
                        }
                        this.mapMarkers = []
                    }
                }
            }
        },

        data () {
            return {
                mdiCloseThick,
                mdiFlashOutline,
                mdiMinusCircle,
                mdiPlusCircle,
                mdiArrowLeftThinCircleOutline,
                expandDates: false,
                expandDocuments: false,
                expandPaymentMilestones: false,
                paymentMilestones: [],
                jobDocuments: [],
                address: {
                    job_id: null,
                    user_id: null,
                    address_line_1: null,
                    google_places_id: null,
                    lat: null,
                    lng: null,
                    state: null,
                    city: null,
                    country: {},
                    timezone: null
                },
                mapMarkers: [],
                workJobDocumentController: null,
                workJobLocationController: null,
                workJobMilestoneController: null
            }
        },

        beforeMount () {
            if (!this.workJobDocumentController) this.workJobDocumentController = new WorkJobDocumentController()
            if (!this.workJobLocationController) this.workJobLocationController = new WorkJobLocationController()
            if (!this.workJobMilestoneController) this.workJobMilestoneController = new WorkJobMilestoneController()
        },

        methods: {
            hideJobOverlay () {
                this.$emit('hideJobOverlay')
            },

            async getJobDocuments () {
                try {
                    this.jobDocuments = await this.workJobDocumentController.retrieve(this.jobToShow.job_id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getJobLocation () {
                try {
                    this.address = await this.workJobLocationController.retrieve(this.jobToShow.job_id)

                    if (this.address.lat && this.address.lng) {
                        this.$nextTick(() => {
                            this.mapMarkers = [
                                {
                                    lat: Number(this.address.lat),
                                    lng: Number(this.address.lng)
                                }
                            ]
                        })
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getJobPaymentMilestones () {
                try {
                    this.paymentMilestones = await this.workJobMilestoneController.retrieve(this.jobToShow.job_id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            }
        }
    }
</script>