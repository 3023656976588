<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-05-11 09:11:59
 * @ Description: Fixer's pending, favourites and search dashboard page in the user's job dashboard section of the app.
 -->

<template>
    <div>
        <v-row>
            <v-col
                cols="12"
                style="position: relative;"
            >
                <!-- Filters -->
                <v-row
                    align="center"
                >
                    <v-col
                        class="flex-shrink-1 flex-grow-0 os-15-sb"
                        v-if="$vuetify.breakpoint.mdAndUp"
                    >
                        Filter:
                    </v-col>
                    <v-col
                        class="flex-grow-1 flex-shrink-0"
                    >
                        <v-row>
                            <v-col
                                :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                            >
                                <v-select
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="os-13-r"
                                    background-color="white"
                                    placeholder="Industry"
                                    hide-details
                                    :items="industries"
                                    return-object
                                    item-text="name"
                                    item-value="id"
                                    v-model="searchIndustry"
                                    clearable
                                    @click:clear="searchIndustry = { id: null }"
                                />
                            </v-col>
                            <v-col
                                :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                            >
                                <v-select
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="os-13-r"
                                    background-color="white"
                                    placeholder="Job Title"
                                    hide-details
                                    :items="jobTitles"
                                    return-object
                                    item-text="name"
                                    item-value="id"
                                    v-model="searchJobTitle"
                                    clearable
                                    @click:clear="searchJobTitle = { id: null }"
                                />
                            </v-col>
                            <v-col
                                :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                            >
                                <v-select
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="os-13-r"
                                    background-color="white"
                                    placeholder="Job Mode"
                                    hide-details
                                    :items="jobModes"
                                    return-object
                                    item-text="name"
                                    item-value="id"
                                    v-model="searchJobMode"
                                    clearable
                                    @click:clear="clearJobMode"
                                />
                            </v-col>
                            <v-col
                                :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                            >
                                <general-google-autocomplete-address-component
                                    @googlePlaceSelected="googlePlaceSelected"
                                    @addressChanged="addressChanged"
                                    :address="address"
                                    :placeholder="locationPlaceholder"
                                    :hideDetails="true"
                                    :clearable="true"
                                    :disabled="!canEnterLocation"
                                    class="mt-n2"
                                />
                            </v-col>
                            <v-col
                                :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                            >
                                <v-text-field
                                    dense
                                    hide-details
                                    outlined
                                    color="#2E567A"
                                    placeholder="Search..."
                                    class="os-13-r"
                                    background-color="white"
                                    v-model="mainSearch"
                                    clearable
                                    @click:clear="clearMainSearch"
                                >
                                    <template v-slot:append>
                                        <v-icon
                                            color="#C5CEE0"
                                            class="ma-0"
                                        >
                                            {{ mdiMagnify }}
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="12"
                        class="text-right pt-0"
                    >
                        <v-btn
                            class="no-uppercase rounded-lg os-13-sb mr-1"
                            color="#2E567A"
                            text
                            @click="clearFilters"
                        >
                            Clear Filters
                        </v-btn>
                        <!-- Search Button -->
                        <v-btn
                            class="white--text no-uppercase rounded-lg os-13-sb"
                            color="#FFA858"
                            @click="filterJobs"
                        >
                            <span v-if="!$store.state.general.customBreakpoints.xxs">Filter Jobs</span>
                            <v-icon
                                class="ml-1"
                            >
                                {{ mdiFilterPlusOutline }}
                            </v-icon>
                        </v-btn>
                    <!-- Search Button -->
                    </v-col>
                </v-row>
                <!-- Filters -->

                <!-- Card View -->
                <v-row
                    v-if="selectedView === 'card'"
                >
                    <!-- No Jobs -->
                    <v-col
                        v-if="items.length === 0 && !isLoading"
                        class="os-12-r text-center"
                        style="color: rgba(0, 0, 0, 0.38);"
                    >
                        {{ noDataText }}
                    </v-col>
                    <!-- No Jobs -->

                    <!-- Jobs -->
                    <v-col
                        cols="12"
                        md="4"
                        lg="3"
                        class="pa-lg-1 pa-xl-3"
                        v-for="(item, index) in items"
                        :key="index"
                        v-else
                    >
                        <!-- Search/Favourite Card -->
                        <work-job-individual-search-favourite-card-component
                            :item="item"
                            :isFavourite="category === 'favourite' || item.is_favourite === 1"
                            @favouriteJob="favouriteJob"
                            @jobDetail="jobDetail"
                            @confirmApplyForJob="confirmApplyForJob"
                            v-if="category === 'all' || category === 'favourite'"
                        />
                        <!-- Search/Favourite Card -->

                        <!-- Pending Card -->
                        <work-job-individual-pending-card-component
                            :item="item"
                            @jobDetail="jobDetail"
                            @jobViewStatus="jobViewStatus"
                            v-if="category === 'pending'"
                        />
                        <!-- Pending Card -->
                    </v-col>
                    <!-- Jobs -->

                    <v-col
                        v-if="isLoading"
                        class="os-12-r text-center"
                        style="color: rgba(0, 0, 0, 0.38);"
                        cols="12"
                    >
                        Loading jobs...
                        <br />
                        <br />
                        <v-progress-circular
                            indeterminate
                            color="#FFA858"
                        />
                    </v-col>
                </v-row>
                <!-- Card View -->

                <!-- List View -->
                <v-row
                    v-if="selectedView === 'list'"
                >
                    <!-- Heading & Search -->
                    <v-col
                        cols="12"
                        class="pb-0 pt-10"
                    >
                        <div
                            class="os-17-sb px-3"
                            style="background-color: white; height: 60px; display: flex; align-items: center;"
                        >
                            All Jobs
                            <v-spacer />
                            <!-- <div
                                style="width: 275px;"
                            >
                                <v-text-field
                                    dense
                                    hide-details
                                    outlined
                                    color="#2E567A"
                                    placeholder="Search..."
                                    class="os-13-r rounded-lg"
                                    background-color="white"
                                    style="width: 275px;"
                                    v-model="search"
                                >
                                    <template v-slot:append>
                                        <v-icon
                                            color="#C5CEE0"
                                            class="ma-0"
                                        >
                                            {{ mdiMagnify }}
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </div> -->
                        </div>
                    </v-col>
                    <!-- Heading & Search -->

                    <!-- Jobs -->
                    <v-col
                        cols="12"
                        class="pt-0"
                    >
                        <v-data-table
                            :headers="tableHeaders"
                            :items="items"
                            item-key="id"
                            hide-default-footer
                            disable-pagination
                            :no-data-text="noDataText"
                            :no-results-text="noResultsText"
                            :search="search"
                        >
                            <template v-slot:[`item.name`]="{ item }">
                                {{ item.name }}
                            </template>
                            <template v-slot:[`item.company_name`]="{ item }">
                                {{ item.company_name }}
                            </template>
                            <template v-slot:[`item.industry_name`]="{ item }">
                                {{ item.industry_name }}
                            </template>
                            <template v-slot:[`item.create_date`]="{ item }">
                                {{ item.create_date }}
                            </template>
                            <template v-slot:[`item.applicant_unread_messages`]="{ item }">
                                <!-- {{ item.messages }} -->
                                {{ item.applicant_unread_messages }} Message<span v-if="item.applicant_unread_messages !== 1">s</span>
                            </template>
                            <template v-slot:[`item.type_name`]="{ item }">
                                {{ item.type_name }}
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <v-btn
                                    icon
                                    @click="jobDetail(item)"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        style="opacity: 0.65;"
                                        size="20"
                                    >
                                        {{ mdiArrowRightThinCircleOutline }}
                                    </v-icon>
                                </v-btn>
                                <!-- <v-btn
                                    icon
                                >
                                    <v-icon
                                        color="#2E567A"
                                        style="opacity: 0.65;"
                                        size="20"
                                    >
                                        {{ mdiPrinterOutline }}
                                    </v-icon>
                                </v-btn> -->
                                <v-btn
                                    icon
                                    @click="jobViewStatus(item)"
                                    v-if="category === 'pending'"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        style="opacity: 0.65;"
                                        size="20"
                                    >
                                        {{ mdiEyeOutline }}
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    @click="confirmApplyForJob(item)"
                                    v-if="category !== 'pending'"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        style="opacity: 0.65;"
                                        size="20"
                                    >
                                        {{ mdiTextBoxPlusOutline }}
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    @click="favouriteJob(item)"
                                    v-if="category !== 'pending'"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        style="opacity: 0.65;"
                                        size="20"
                                        v-if="category === 'all'"
                                    >
                                        {{ mdiHeartOutline }}
                                    </v-icon>
                                    <v-icon
                                        color="#E2574C"
                                        v-else
                                    >
                                        {{ mdiHeart }}
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                    <!-- Jobs -->

                    <v-col
                        v-if="isLoading"
                        class="os-12-r text-center"
                        style="color: rgba(0, 0, 0, 0.38);"
                        cols="12"
                    >
                        Loading jobs...
                        <br />
                        <br />
                        <v-progress-circular
                            indeterminate
                            color="#FFA858"
                        />
                    </v-col>
                </v-row>
                <!-- List View -->
            </v-col>
        </v-row>

        <!-- Confirm Apply Overlay -->
        <work-general-applicant-confirm-apply-overlay-component
            :showApplyOverlayBoolean="showApplyOverlayBoolean"
            :jobToApplyFor="jobToApplyFor"
            @toggleApplyOverlayComponent="toggleApplyOverlayComponent"
        />
        <!-- Confirm Apply Overlay -->

        <!-- Job Detail Overlay -->
        <work-job-detail-view-overlay-component
            :showDialog="showDialog"
            :jobToShow="jobToShow"
            @hideJobOverlay="hideJobOverlay"
        />
        <!-- Job Detail Overlay -->
    </div>
</template>
<script>
    import WorkGeneralApplicantConfirmApplyOverlayComponent from '@/overlays/work/general/applicant/WorkGeneralApplicantConfirmApplyOverlayComponent.vue'
    import { mdiEyeOutline, mdiMagnify, mdiArrowRightThinCircleOutline, mdiPrinterOutline, mdiHeartOutline, mdiTextBoxPlusOutline, mdiHeart, mdiFilterPlusOutline } from '@mdi/js'
    import { LookupIndustryController, LookupJobTitleController, LookupJobController, LookupLocationController, WorkJobBoardController, WorkJobFavouriteController } from '@/logic/controller/index.controller.js'
    import GeneralGoogleAutocompleteAddressComponent from '@/components/general/google/GeneralGoogleAutocompleteAddressComponent.vue'
    import WorkJobIndividualSearchFavouriteCardComponent from '@/components/work/job/individual/WorkJobIndividualSearchFavouriteCardComponent.vue'
    import WorkJobIndividualPendingCardComponent from '@/components/work/job/individual/WorkJobIndividualPendingCardComponent.vue'
    import WorkJobDetailViewOverlayComponent from '@/overlays/work/job/detail/WorkJobDetailViewOverlayComponent.vue'

    export default {
        name: 'WorkJobDashboardIndividualSearchFavouritesPendingPage',

        components: {
            WorkGeneralApplicantConfirmApplyOverlayComponent,
            GeneralGoogleAutocompleteAddressComponent,
            WorkJobIndividualSearchFavouriteCardComponent,
            WorkJobIndividualPendingCardComponent,
            WorkJobDetailViewOverlayComponent
        },

        props: {
            selectedView: {
                type: String,
                required: true
            },

            selectedNavItem: {
                type: String,
                required: true
            }
        },

        watch: {
            selectedNavItem: {
                async handler (value) {
                    let shouldRetrieve
                    if (this.$store.state.user.userContext === 'individual' && (value === 'All Jobs' || value === 'Pending Jobs' || value === 'Favourites')) shouldRetrieve = true
                    else shouldRetrieve = false

                    if (shouldRetrieve) {
                        this.items = []
                        this.mainSearch = ''
                        this.lastPageLoaded = 0
                        this.totalPages = 0
                        this.isLoading = false
                        this.searchIndustry = {
                            id: null
                        }
                        this.searchJobTitle = {
                            id: null
                        }
                        this.searchJobMode = {
                            id: null
                        }
                        this.searchCountry = {
                            id: null
                        }
                        this.searchState = null
                        this.searchCity = null
                        this.address = {
                            address_line_1: null
                        }

                        this.getJobCategory()
                        await this.getJobs()
                    }
                }
            },

            searchJobMode: {
                handler (value) {
                    if (value) {
                        if (value.id === 1) {
                            this.searchCountry = {
                                id: null
                            }
                            this.searchState = null
                            this.searchCity = null

                            this.address = {
                                address_line_1: null
                            }
                        }
                    }
                }
            }
        },

        computed: {
            canEnterLocation () {
                if (this.searchJobMode) {
                    if (this.searchJobMode.id) {
                        if (this.searchJobMode.id === 2) return true
                        else return false
                    } else return false
                } else return false
            },

            locationPlaceholder () {
                if (this.searchJobMode) {
                    if (this.searchJobMode.id) {
                        if (this.searchJobMode.id === 2) return 'Location'
                        else return 'Select "On Location" mode'
                    } else return 'Select "On Location" mode'
                } else return 'Select "On Location" mode'
            },

            tableHeaders () {
                if (this.category === 'pending') {
                    return [
                        {
                            text: 'Job Name',
                            value: 'name'
                        },
                        {
                            text: 'Company',
                            value: 'company_name'
                        },
                        {
                            text: 'Industry',
                            value: 'industry_name'
                        },
                        {
                            text: 'Type',
                            value: 'type_name'
                        },
                        {
                            text: 'Application Date',
                            value: 'create_date'
                        },
                        {
                            text: 'Messages',
                            value: 'applicant_unread_messages'
                        },
                        {
                            text: 'Action',
                            value: 'action'
                        }
                    ]
                } else {
                    return [
                        {
                            text: 'Job Name',
                            value: 'name'
                        },
                        {
                            text: 'Company',
                            value: 'company_name'
                        },
                        {
                            text: 'Industry',
                            value: 'industry_name'
                        },
                        {
                            text: 'Type',
                            value: 'type_name'
                        },
                        {
                            text: 'Action',
                            value: 'action'
                        }
                    ]
                }
            },

            noResultsText () {
                return 'No results found for: "' + this.search + '"'
            },

            noDataText () {
                if (this.category === 'all') return 'No jobs'
                if (this.category === 'pending') return 'No job applications'
                else return 'No ' + this.category + ' jobs'
            }
        },

        data () {
            return {
                showDialog: false,
                jobToShow: {
                    name: '',
                    description: null,
                    start_date: null,
                    end_date: null,
                    image_url: null,
                    status: {
                        id: null
                    },
                    industry: {
                        id: null
                    },
                    job_title: {
                        id: null
                    },
                    type: {
                        id: 1
                    },
                    mode: {
                        id: 1
                    }
                },
                items: [],
                mdiEyeOutline,
                mdiMagnify,
                mdiArrowRightThinCircleOutline,
                mdiPrinterOutline,
                mdiHeartOutline,
                mdiTextBoxPlusOutline,
                mdiHeart,
                mdiFilterPlusOutline,
                showApplyOverlayBoolean: false,
                jobToApplyFor: {},
                lookupIndustryController: null,
                lookupJobTitleController: null,
                lookupJobController: null,
                lookupLocationController: null,
                workJobBoardController: null,
                workJobFavouriteController: null,
                jobModes: [],
                industries: [],
                jobTitles: [],
                searchIndustry: {
                    id: null
                },
                searchJobTitle: {
                    id: null
                },
                searchJobMode: {
                    id: null
                },
                searchCountry: {
                    id: null
                },
                searchState: null,
                searchCity: null,
                countries: [],
                address: {
                    job_id: null,
                    user_id: null,
                    address_line_1: null,
                    google_places_id: null,
                    lat: null,
                    lng: null,
                    state: null,
                    city: null,
                    country: {},
                    timezone: null
                },
                category: null,
                search: '',
                mainSearch: '',
                lastPageLoaded: 0,
                totalPages: 0,
                isLoading: true
            }
        },

        beforeMount () {
            this.lookupIndustryController = new LookupIndustryController()
            this.lookupJobTitleController = new LookupJobTitleController()
            this.lookupJobController = new LookupJobController()
            this.lookupLocationController = new LookupLocationController()
            this.workJobBoardController = new WorkJobBoardController()
            this.workJobFavouriteController = new WorkJobFavouriteController()
        },

        async mounted () {
            this.getJobModes()
            this.getIndustries()
            this.getJobTitles()
            this.getCountries()
            this.getJobCategory()
            await this.getJobs()

            var self = this
        
            const jobContainer = document.getElementById('jobContainer')
            jobContainer.addEventListener('scroll', async () => {
                if (this.selectedView === 'card') {
                    if (jobContainer.offsetHeight + jobContainer.scrollTop + 500 >= jobContainer.scrollHeight) {
                        if (!self.isLoading && self.lastPageLoaded < self.totalPages) {
                            await this.getJobs()
                        }
                    }
                } else {
                    if (jobContainer.offsetHeight + jobContainer.scrollTop + 120 >= jobContainer.scrollHeight) {
                        if (!self.isLoading && self.lastPageLoaded < self.totalPages) {
                            await this.getJobs()
                        }
                    }
                }
            })
        },

        methods: {
            getJobCategory () {
                switch (this.selectedNavItem) {
                case 'All Jobs':
                    this.category = 'all'
                    window.gtag('event', 'job_search_dashboard_loaded')
                    break;
                case 'Pending Jobs':
                    this.category = 'pending'
                    break;
                case 'Favourites':
                    this.category = 'favourite'
                    break;
                }
            },

            async getJobModes () {
                try {
                    this.jobModes = await this.lookupJobController.getJobModes()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getIndustries () {
                try {
                    this.industries = await this.lookupIndustryController.get(false, false, true, false, false, false)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getJobTitles () {
                try {
                    this.jobTitles = await this.lookupJobTitleController.get(false, false, true, false, false, false)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getCountries () {
                try {
                    this.countries = await this.lookupLocationController.getCountries()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async filterJobs () {
                this.lastPageLoaded = 0
                this.items = []
                await this.getJobs()
                if (this.selectedNavItem === 'All Jobs') window.gtag('event', 'job_search_dashboard_filtered')
            },

            async clearFilters () {
                this.lastPageLoaded = 0
                this.items = []
                this.searchIndustry = {
                    id: null
                }
                this.searchJobTitle = {
                    id: null
                }
                this.searchJobMode = {
                    id: null
                }
                this.searchCountry = {
                    id: null
                }
                this.searchState = null
                this.searchCity = null
                this.mainSearch = ''
                this.address = {
                    address_line_1: null
                }
                await this.getJobs()
            },

            clearJobMode () {
                this.searchJobMode = {
                    id: null
                }
                this.searchCountry = {
                    id: null
                }
                this.searchState = null
                this.searchCity = null

                this.address = {
                    address_line_1: null
                }
            },

            async getJobs () {
                try {
                    this.isLoading = true

                    let query = {}

                    let industryID = null
                    if (this.searchIndustry) {
                        if (this.searchIndustry.id) {
                            industryID = this.searchIndustry.id
                            query.industryID = industryID
                        }
                    }

                    let jobTitleID = null
                    if (this.searchJobTitle) {
                        if (this.searchJobTitle.id) {
                            jobTitleID = this.searchJobTitle.id
                            query.jobTitleID = jobTitleID
                        }
                    }

                    let modeID = null
                    if (this.searchJobMode) {
                        if (this.searchJobMode.id) {
                            modeID = this.searchJobMode.id
                            query.modeID = modeID
                            if (modeID === 1) {
                                this.searchState = null
                                this.searchCity = null
                                this.searchCountry = { id: null }

                                this.address = {
                                    address_line_1: null
                                }
                            }
                        } else {
                            this.searchState = null
                            this.searchCity = null
                            this.searchCountry = { id: null }

                            this.address = {
                                address_line_1: null
                            }
                        }
                    } else {
                        this.searchState = null
                        this.searchCity = null
                        this.searchCountry = { id: null }

                        this.address = {
                            address_line_1: null
                        }
                    }

                    let countryID = null
                    if (this.searchCountry) {
                        if (this.searchCountry.id) {
                            countryID = this.searchCountry.id
                            query.countryID = countryID
                        }
                    }

                    if (this.searchState) query.state = this.searchState
                    if (this.searchCity) query.city = this.searchCity

                    // if (Object.keys(query).length > 0) {
                    //     let routeQuery = this.$route.query
                    //     if (Object.keys(routeQuery).length > 0) {
                    //         for(var prop in routeQuery) {
                    //             query[prop] = routeQuery[prop]
                    //         }
                    //     }
                    // } else {
                    //     query.view = this.$route.query.view
                    // }

                    // if (query != this.$route.query) this.$router.replace({ query })

                    this.lastPageLoaded = Number(this.lastPageLoaded) + 1

                    let recordsToRetrieve = 10

                    if (this.selectedView === 'card') recordsToRetrieve = 10
                    else recordsToRetrieve = 20

                    const response = await this.workJobBoardController.retrieve(this.lastPageLoaded, recordsToRetrieve, this.mainSearch, this.category, industryID, jobTitleID, null, modeID, countryID, this.searchState, this.searchCity)

                    if (response && response.length > 0) {
                        this.totalPages = response[0].page_total
                        for (let index = 0; index < response.length; index++) {
                            const item = response[index]

                            if (item.create_date) {
                                const removeTime = item.create_date.split(' ')[0]
                                const convertDate = new Date(removeTime)
                                const splitConvertedDate = convertDate.toString().split(' ')
                                item.create_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1] + ' ' + splitConvertedDate[3]
                            }

                            this.items.push(item)
                        }
                    }

                    this.isLoading = false

                    this.$nextTick(async () => {
                        const jobContainer = document.getElementById('jobContainer')
                        if (response && response.length > 0 && this.lastPageLoaded < this.totalPages && jobContainer.offsetHeight + jobContainer.scrollTop === jobContainer.scrollHeight) await this.getJobs()
                    })                
                } catch (error) {
                    this.isLoading = false

                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            clearMainSearch () {
                this.mainSearch = ''
            },

            googlePlaceSelected (place) {
                if (place) {
                    Object.assign(this.address, place)

                    this.searchState = place.state
                    this.searchCity = place.city

                    const countryIndex = this.countries.findIndex(country => country.iso_2 === place.country.country_code)
                    if (countryIndex > -1) this.searchCountry.id = this.countries[countryIndex].id
                } else {
                    this.searchState = null
                    this.searchCity = null
                    this.searchCountry = { id: null }

                    this.address = {
                        job_id: null,
                        user_id: null,
                        address_line_1: null,
                        google_places_id: null,
                        lat: null,
                        lng: null,
                        state: null,
                        city: null,
                        country: {},
                        timezone: null
                    }
                }
            },

            addressChanged (address) {
                this.address.address_line_1 = address
            },

            toggleApplyOverlayComponent (value, confirmApplication) {
                this.showApplyOverlayBoolean = value

                if (confirmApplication) this.applyForJob(this.jobToApplyFor)
            },

            async favouriteJob (job) {
                try {
                    await this.workJobFavouriteController.toggleFavourite(job)

                    if (this.category === 'all') {
                        const jobIndex = this.items.findIndex(itemToFind => itemToFind.job_id === job.job_id)

                        if (jobIndex > -1) {
                            if (this.items[jobIndex].is_favourite === 0) {
                                this.$store.commit('setSnackbar', {
                                    text: 'Added to favourites',
                                    show: true,
                                    color: '#069B34'
                                })

                                this.items[jobIndex].is_favourite = 1
                            } else if (this.items[jobIndex].is_favourite === 1) {
                                this.$store.commit('setSnackbar', {
                                    text: 'Removed from favourites',
                                    show: true,
                                    color: '#069B34'
                                })

                                this.items[jobIndex].is_favourite = 0
                            }
                        }
                    } else if (this.category === 'favourite') {
                        const jobIndex = this.items.findIndex(itemToFind => itemToFind.job_id === job.job_id)
                        if (jobIndex > -1) this.items.splice(jobIndex, 1)

                        this.$store.commit('setSnackbar', {
                            text: 'Removed from favourites',
                            show: true,
                            color: '#069B34'
                        })
                    }

                    if (this.items.length === 0 && this.lastPageLoaded < this.totalPages) await this.getJobs()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            jobDetail (job) {
                this.jobToShow = job
                this.showDialog = true

                if (this.selectedNavItem === 'All Jobs') window.gtag('event', 'job_view_detail_' + job.job_id)
            },

            hideJobOverlay () {
                this.jobToShow = {
                    name: '',
                    description: null,
                    start_date: null,
                    end_date: null,
                    image_url: null,
                    status: {
                        id: null
                    },
                    industry: {
                        id: null
                    },
                    job_title: {
                        id: null
                    },
                    type: {
                        id: 1
                    },
                    mode: {
                        id: 1
                    }
                },
                this.showDialog = false
            },

            confirmApplyForJob (job) {
                this.jobToApplyFor = job
                this.toggleApplyOverlayComponent(true)
            },

            applyForJob (job) {
                this.$router.push(`/job/${job.job_id}/application`)
            },

            jobViewStatus (job) {
                if (this.$route.path !== `/job/${job.job_id}/applicant/${this.$store.state.user.userData.id}`) this.$router.push(`/job/${job.job_id}/applicant/${this.$store.state.user.userData.id}`)
            }
        }
    }
</script>
<style scoped>
    .four-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        white-space: normal;
    }

    .two-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: normal;
    }

    >>> .v-list-item {
        min-height: 0;
        height: 30px;
    }

    >>> th {
        font-size: 15px !important;
        font-weight: 600 !important;
        color: #8F9BB3 !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> td {
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
        max-width: 250px;
    }

    >>> thead {
        background-color: #EFF4FA !important;
    }

    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }
</style>
